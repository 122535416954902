.flex-input-container {
    display: grid;
}

.flex-input-container::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
}

.flex-input-container > textarea {
    resize: none;
    overflow: hidden;
}

.flex-input-container > textarea:focus {
    border: 1px solid #56BA8E;
    outline: none;
    box-shadow: none;
}

.flex-input-container > textarea,
.flex-input-container::after {
    border-radius: 0.5rem;
    border: 1px solid #00000010;
    padding: 0.5rem;
    background-color: white;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
}
